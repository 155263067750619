<template>
  <div class="text-center mx-auto" style="max-width: 800px">
      <LocaleChanger/>
       <Login/>
  </div>
</template>

<script>
import Login from '../components/Login.vue'
import LocaleChanger from '../components/LocaleChanger.vue'

export default {
    components: {
        Login,
        LocaleChanger
    }
}
</script>

